<template>
    <div>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-show="mostrar">
            <v-card class="borde-card" elevation="0">
                <v-card-title class="pl-8 pt-7 pb-0">
                    <i class="material-icons-outlined icono">beenhere</i>
                    <div class="leyenda col-10 py-0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contratos
                    </div>
                    <br>
                    <div class="leyendaGrafica col-12 pr-0 pl-10 py-0">
                        Entregados-faltantes
                    </div>
                </v-card-title>
                <v-card-text>
                    <div
                        slot="widget-content"
                        id="chartContainer"
                        style="height:300px; width:100%; margin-bottom:20px;"
                    >
                        <v-chart 
                            autoresize 
                            :option="option"
                            @mouseover="mouseover_contratos01"
                            @mouseout="mouseout_contratos01"  
                            style="width:100%; min-height:300px; position:relative; margin-bottom:0px"
                        />
                    </div>
                </v-card-text>
            </v-card>


            <v-card class="borde-card mt-5" elevation="0">
                <v-card-title class="pl-8 pt-7 pb-0">
                    <i class="material-icons-outlined icono">beenhere</i>
                    <div class="leyenda col-10 py-0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contratos
                    </div>
                    <br>
                    <div class="leyendaGrafica col-12 pr-0 pl-10 py-0">
                        Por estatus
                    </div>
                </v-card-title>
                <v-card-text>
                    <div
                        slot="widget-content"
                        id="chartContainer"
                        style="height:300px; width:100%; margin-bottom:20px;"
                    >
                        <v-chart 
                            autoresize 
                            :option="option2"
                            @mouseover="mouseover_contratos02"
                            @mouseout="mouseout_contratos02"  
                            style="width:100%; min-height:300px; position:relative; margin-bottom:0px"
                        />
                    </div>
                </v-card-text>
            </v-card>

            <v-card class="borde-card mt-5" elevation="0">
                <v-card-title class="pl-8 pt-7 pb-0">
                    <i class="material-icons-outlined icono">beenhere</i>
                    <div class="leyenda col-10 py-0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contratos
                    </div>
                    <br>
                    <div class="leyendaGrafica col-12 pr-0 pl-10 py-0">
                        Por tipo de contrato
                    </div>
                </v-card-title>
                <v-card-text>
                    <div
                        slot="widget-content"
                        id="chartContainer"
                        style="height:300px; width:100%; margin-bottom:20px;"
                    >
                        <v-chart 
                            autoresize 
                            :option="option3"
                            @mouseover="mouseover_contratos03"
                            @mouseout="mouseout_contratos03"  
                            style="width:100%; min-height:300px; position:relative; margin-bottom:0px"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>   

<script>
export default {
    data() {
        return {
            mostrar: false,
            option: {
                textStyle: {
                    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
                },
                tooltip : {
                    trigger : "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle       : {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        name: "Traffic Sources",
                        type: "pie",
                        radius: ["50%", "70%"],
                        center: ["50%", "50%"],
                        label   : {
                            normal  : {
                                show    : true,
                                position: "center",
                                formatter   : (params) => {
                                    return "{top| Contratos1}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show    : true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name +"}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        data: [
                            { value: 10, name: "Entregados" },
                            { value: 20, name: "Faltantes" }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            },
            option2: {
                textStyle: {
                    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
                },
                tooltip : {
                    trigger : "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle       : {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        name: "Traffic Sources",
                        type: "pie",
                        radius: ["50%", "70%"],
                        center: ["50%", "50%"],
                        label   : {
                            normal  : {
                                show    : true,
                                position: "center",
                                formatter   : (params) => {
                                    return "{top| Contratos1}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show    : true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name +"}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        data: [
                            {value: 10, name: "Listos para entregar"},
                            {value: 20, name: "Vencidos"},
                            {value: 10, name: "Incompletos"},
                            {value: 20, name: "Cancelados"},
                            {value: 10, name: "Firmados"},
                            {value: 20, name: "Pendientes por renovar"}
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            },
            option3: {
                textStyle: {
                    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
                },
                tooltip : {
                    trigger : "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle       : {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        name: "Traffic Sources",
                        type: "pie",
                        radius: ["50%", "70%"],
                        center: ["50%", "50%"],
                        label   : {
                            normal  : {
                                show    : true,
                                position: "center",
                                formatter   : (params) => {
                                    return "{top| Contratos1}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show    : true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name +"}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        data: [
                            {value: 10, name: "Contrato indeterminado"},
                            {value: 20, name: "Contrato de trabajo para obra determinada"},
                            {value: 10, name: "Contato determinado"},
                            {value: 20, name: "Contrato de trabajo por temporada"},
                            {value: 10, name: "Contrato de trabajo sujeto a prueba"},
                            {value: 20, name: "Contrato de trabajo con capacitación inicial"},
                            {value: 10, name: "Modalidad de trabajo por comisión laboral"},
                            {value: 20, name: "Modalidadades de contratación donde no existe relación de trabajo"},
                            {value: 10, name: "Jubilación, pensión, retiro"},
                            {value: 10, name: "Otro contrato"},
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            }
        }
    },
    methods:{
        mouseover_contratos01(event, chartContext, config) {
            this.option.series[0].label.normal.show = false;
        },
        mouseout_contratos01(event, chartContext, config) {
            this.option.series[0].label.normal.show = true;
        },
        mouseover_contratos02(event, chartContext, config) {
            this.option2.series[0].label.normal.show = false;
        },
        mouseout_contratos02(event, chartContext, config) {
            this.option2.series[0].label.normal.show = true;
        },
        mouseover_contratos03(event, chartContext, config) {
            this.option3.series[0].label.normal.show = false;
        },
        mouseout_contratos03(event, chartContext, config) {
            this.option3.series[0].label.normal.show = true;
        },
    },
    mounted() {
        this.mostrar=true;
    },
}
</script>

<style scoped>
    .icono {
        color: #2DB9D1;
    }
    
    .leyenda {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: rgba(16, 28, 90);
    }
    .leyendaGrafica {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
</style>